<template>
  <div class=" h-100-vh">
    <div class="card card-ecart ">
      <div class="card-header">
        <h5 v-text=" $t('dashboard.menu.pickups') "> </h5>
      </div>
      <div class="card-body" v-if="!isLoading">
        <div class="row justify-content-center justify-content-md-between align-items-end">
          <div class="col-auto col-md-auto">
            <p>
              <strong class="f-w-500" v-text="$t('general.form.date') + ': ' "> </strong>
              <span v-text="fnDate( pickup.shipment.pickup.date,  CONST.DATE_FORMATS.DD_MM_YYYY )"> </span>
            </p>
          </div>
          <div class="col-auto col-md-auto">
            <p class=" ">
              <strong class="f-w-500" v-text=" $t('tables.carrier') + ': ' "> </strong>
              <span class="text-uppercase" v-text=" pickup.shipment.carrier "></span>
            </p>
          </div>
          <div class="col-auto col-md-auto">
            <p>
              <strong class="f-w-500" v-text="$t('general.schedule') + ': ' "></strong>
              <span v-text="pickup.shipment.pickup.time_from + ':00 - ' "> </span>
              <span v-text="pickup.shipment.pickup.time_to + ':00' "> </span>
            </p>
          </div>
        </div>
      </div>
      <div class="card-footer" v-if=" !isLoading ">
        <div class="row justify-content-center ">
          <div class="col-auto">
            <img :class=" 'img-' + pickup.shipment.carrier " class=" img-contain-150 bg-white " alt="">
          </div>
        </div>
        <hr class="w-50">
        <p class="text-center">
          <strong class="f-w-500" v-text="$t('general.pickupNumber')"> </strong> <br>
          <strong v-text="pickup.pickup_number"> </strong>
        </p>
      </div>
      <div class="card-footer" v-if="!isLoading">
        <p>
          <strong class="f-w-500" v-text="$t('general.instructions') + ': ' "></strong> <br>
          <span v-text="pickup.shipment.pickup.instructions"></span>
        </p>
      </div>
      <div class="card-footer" v-if="!isLoading">

        <p>
          <span v-text=" $t('tables.store') + ': ' + pickup.origin.company "> </span> <br>
          <span v-text=" $t('general.form.email') + ': ' + pickup.origin.email "> </span>
        </p>
        <p>
          <span v-text=" $t('tables.address') + ': ' "></span>
          <span v-text="fnFormatAddress( pickup.origin.address )"></span>
        </p>
      </div>

      <div class="card-body" v-if="isLoading">
        <no-data :isLoading="isLoading " :dataMessage="$t('noDataMessages.errorDefault')" :showBtn="false">
        </no-data>
      </div>

    </div>

  </div>
</template>

<script>
  import {
    EcNewPickup,
  } from "@/plugins/beans.js"

  export default {

    data() {
      return ({
        isLoading: true,
        pickup: EcNewPickup(),
      })
    },

    methods: {
      async fnApiGetPickup() {
        await axios.get('pickups/' + this.$route.params.id).then(response => {
          this.pickup = response.data
        }).catch(error => {});
        this.isLoading = false;
      },
    },
    mounted() {
      this.fnApiGetPickup();
    }
  }
</script>